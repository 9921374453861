/**
 * v0 by Vercel.
 * @see https://v0.dev/t/bz8QpogGrc1
 * Documentation: https://v0.dev/docs#integrating-generated-code-into-your-nextjs-app
 */

import { SingleDocResponseData } from "~/api/query_fns/documents";
import { StepReportComponent } from "../components/StepReportComponent";
import AdditionalInformation from "~/components/AdditionalInformation";
import { Card } from "~/components/ui/card";
import UploadArea from "./Documents/UploadArea";
import { useGenerateReportNew } from "~/hooks/useGenerateReportNew";
import { LoadingComponent } from "~/utils/util-report";
import { Input } from "~/components/ui/input";
import { useState } from "react";

export default function UCClaimCoverageNew() {
  const {
    isLoading,
    handleGenerateReport,
    additionalNotes,
    setAdditionalNotes,
    handleUploadComplete,
    claimNumber,
    setClaimNumber,
  } = useGenerateReportNew("policy_fact");

  const [isClaimNumberComplete, setIsClaimNumberComplete] = useState(false);

  const handleClaimNumberChange = (value: string) => {
    setClaimNumber(value);
    setIsClaimNumberComplete(value.trim() !== "");
  };

  if (isLoading) {
    return <LoadingComponent title="GENERATING COVERAGE ANALYSIS" />;
  }

  return (
    <StepReportComponent
      title="Generate Claim Coverage"
      lastStepName="Generate Coverage Report"
      steps={[
        {
          number: 1,
          label: "Claim Number",
          description: "Enter Claim or Matter Number",
          component: (
            <ClaimNumberComponent
              claimNumber={claimNumber}
              setClaimNumber={handleClaimNumberChange}
            />
          ),
          isMandatory: true,
          isComplete: isClaimNumberComplete,
        },
        {
          number: 2,
          label: "Policy Documents",
          description: "Upload policy documents.",
          component: (
            <PolicyDocumentsComponent
              handleUploadComplete={handleUploadComplete}
            />
          ),
        },
        {
          number: 3,
          label: "Fact Patterns",
          description: "Upload any facts related to this claim.",
          component: (
            <FactPatternsComponent
              handleUploadComplete={handleUploadComplete}
            />
          ),
        },
        {
          number: 4,
          label: "Additional Information",
          description:
            "Upload or describe any additional information relevant for the analysis. Please note that this text box will not change the format of this report. If you would like to generate a custom report with your own instructions or formatting, please use the Custom Report tool from the home screen.",
          component: (
            <AdditionalInformationComponent
              additionalNotes={additionalNotes}
              setAdditionalNotes={setAdditionalNotes}
              handleUploadComplete={handleUploadComplete}
            />
          ),
        },
      ]}
      onFinalStep={handleGenerateReport} // Pass the final step handler
      onCancelPath="/claim-coverage"
    />
  );
}

const PolicyDocumentsComponent = ({
  handleUploadComplete,
}: {
  handleUploadComplete: (docResponse: SingleDocResponseData) => void;
}) => {
  return (
    <div id="step1" className="flex w-full flex-col">
      <Card className="p-3 ">
        <UploadArea
          db_document_type="policy"
          onUploadComplete={handleUploadComplete}
        />
      </Card>
    </div>
  );
};

const AdditionalInformationComponent = ({
  additionalNotes,
  setAdditionalNotes,
  handleUploadComplete,
}: {
  additionalNotes: string;
  setAdditionalNotes: (notes: string) => void;
  handleUploadComplete: (docResponse: SingleDocResponseData) => void;
}) => {
  return (
    <AdditionalInformation
      additionalNotes={additionalNotes}
      setAdditionalNotes={setAdditionalNotes}
      handleUploadComplete={handleUploadComplete}
    />
  );
};

const FactPatternsComponent = ({
  handleUploadComplete,
}: {
  handleUploadComplete: (docResponse: SingleDocResponseData) => void;
}) => {
  return (
    <div id="step2" className="flex w-full flex-col">
      <Card className=" p-3">
        <UploadArea
          db_document_type="fact"
          onUploadComplete={handleUploadComplete}
        />
      </Card>
    </div>
  );
};

interface ClaimNumberComponentProps {
  claimNumber: string;
  setClaimNumber: (claimNumber: string) => void;
}

const ClaimNumberComponent: React.FC<ClaimNumberComponentProps> = ({
  claimNumber,
  setClaimNumber,
}) => {
  return (
    <div>
      <Input
        className="mt-2"
        type="text"
        id="claimNumber"
        name="claimNumber"
        placeholder="Example: 0001_CLAIM"
        value={claimNumber}
        onChange={(e) => setClaimNumber(e.target.value)}
        required
      />
    </div>
  );
};
