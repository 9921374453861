import { LoaderFunctionArgs, useLoaderData, Await } from "react-router-dom";
import { defer } from "react-router-dom";
import { ReportTabs } from "~/components/ReportTabs";
import { fetchJobReport } from "~/api/query_fns/coverage-analysis";
import { CopilotKit, useCopilotReadable } from "@copilotkit/react-core";
import { SingleDocResponseData } from "~/api/query_fns/documents";
import { useOpenReferenceDetails } from "../doc-util";
import { PDFViewerDialog } from "~/components/PDFViewerDialog";
import { CustomChat } from "~/components/util-chat";
import { JobProcessingStatus } from "~/api/query_fns/coverage-analysis";
import React, { useState } from "react";
import {
  generateInitialMessage,
  getContentTypes,
  generateChatContext,
  getDocumentDetails,
} from "~/utils/util-report";
import { LoadingComponent } from "~/utils/util-report";
import ErrorBoundaryReport from "~/components/ErrorBoundaryReport";
import { Message } from "../components/CustomMessages";

interface LoaderResponse {
  jobId: string;
  reportName: string;
  documents: SingleDocResponseData[];
  fullCoverageReport: string;
  reportKey: string;
  reportType: string;
  from: string;
  followOnQuestions: string[];
  chatMessages?: Message[];
}

export const reportPrivateAnalysisLoader = async ({
  params,
}: LoaderFunctionArgs) => {
  const fetchReportData = async (): Promise<LoaderResponse> => {
    let retryCount = 0;
    const maxRetries = 80; // Increased from 40 to 80
    const retryDelay = 15000; // 15 seconds in milliseconds

    while (retryCount < maxRetries) {
      try {
        const response = await fetchJobReport({
          jobId: params.job_id || "",
          lastChatMessageId: params.chat_message_id,
        });

        if (
          response.processingStatus === JobProcessingStatus.Complete ||
          response.processingStatus === JobProcessingStatus.Exempt
        ) {
          const mappedChatMessages =
            response.chatMessages?.map((chatMessage) => ({
              id: chatMessage.id,
              content: chatMessage.message,
              role: chatMessage.messageType as "user" | "assistant",
              createdAt: new Date(chatMessage.createdAt),
              isVisible: true,
              chatId: chatMessage.chatId,
            })) || [];

          return {
            jobId: params.job_id || "",
            reportName: response.reportName,
            documents: response.documents,
            fullCoverageReport: response.coverageReport,
            reportKey: response.reportKey || "",
            reportType: response.reportType,
            from: response.from,
            followOnQuestions: response.followOnQuestions || [],
            chatMessages: mappedChatMessages,
          };
        } else if (response.processingStatus === JobProcessingStatus.Deleted) {
          throw new Error("Job has been deleted");
        } else {
          console.log(
            `Current processing status: ${response.processingStatus}. Retrying in 15 seconds...`
          );
          await new Promise((resolve) => setTimeout(resolve, retryDelay));
          retryCount++;
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    }
    throw new Error("Generating Report Error: Timeout after 20 minutes");
  };

  return defer({ reportData: fetchReportData() });
};

const GenReportPrivate = () => {
  const { reportData } = useLoaderData() as {
    reportData: Promise<LoaderResponse>;
  };

  const [reportChatMessages, setReportChatMessages] = useState<Message[]>([]);

  const appendToReportChatMessages = (newMessages: Message[]) => {
    setReportChatMessages((prevMessages) => [...prevMessages, ...newMessages]);
  };

  const [loadedData, setLoadedData] = React.useState<LoaderResponse | null>(
    null
  );

  const {
    isDocViewerOpen,
    initialPage,
    document,
    setDocViewerState,
    openReference,
  } = useOpenReferenceDetails(loadedData?.documents || []);

  React.useEffect(() => {
    reportData.then((data) => {
      setLoadedData(data);
      if (data.chatMessages) {
        setReportChatMessages(data.chatMessages);
      }
    });
  }, [reportData]);

  const context = React.useMemo(() => {
    if (!loadedData) return "";
    const { documents, fullCoverageReport } = loadedData;
    const { factContent, policyContent, additionalContent, referenceContent } =
      getContentTypes(documents.map((doc) => doc.document));
    return generateChatContext(
      fullCoverageReport,
      factContent,
      policyContent,
      additionalContent,
      referenceContent
    );
  }, [loadedData]);

  useCopilotReadable({
    description: "all documentations",
    value: context,
  });

  return (
    <React.Suspense
      fallback={
        <LoadingComponent title="Generating Report" showProgressBar={true} />
      }
    >
      <Await resolve={reportData} errorElement={<ErrorBoundaryReport />}>
        {(loadedData: LoaderResponse) => {
          const {
            jobId,
            reportName,
            reportKey,
            documents,
            fullCoverageReport,
            reportType,
            from,
            followOnQuestions,
          } = loadedData;

          // Inside the Coverage component
          const policyDetails = getDocumentDetails(documents, "policy");
          const factDetails = getDocumentDetails(documents, "fact");

          console.log("Policy File Name: ", policyDetails.fileName);
          console.log("Policy Presigned URL: ", policyDetails.presignedUrl);
          console.log("Fact File Name: ", factDetails.fileName);
          console.log("Fact Presigned URL: ", factDetails.presignedUrl);

          const initialMessage = generateInitialMessage(
            reportType,
            from,
            followOnQuestions
          );

          return (
            <CopilotKit
              url={`${import.meta.env.VITE_COPILOT_API_URL}/api/copilot`}
            >
              <CustomChat
                context={context}
                initialMessage={initialMessage}
                documents={documents}
                reportKey={reportKey}
                openReference={openReference}
                reportSource="auth_ui_report"
                appendToReportChatMessages={appendToReportChatMessages}
                initialChatMessages={loadedData.chatMessages}
              >
                <div className="w-[48vw]">
                  <PDFViewerDialog
                    open={isDocViewerOpen}
                    doc={document}
                    initialPage={initialPage}
                    setDocViewerState={setDocViewerState}
                  />

                  <div className="mx-auto w-full max-w-[90vw]">
                    <ReportTabs
                      reportId={jobId}
                      reportName={reportName}
                      fullCoverageReport={fullCoverageReport}
                      docs={documents}
                      openReference={openReference}
                      reportChatMessages={reportChatMessages}
                      documentNames={documents.map(
                        (doc) => doc.document.filename
                      )}
                    />
                  </div>
                </div>
              </CustomChat>
            </CopilotKit>
          );
        }}
      </Await>
    </React.Suspense>
  );
};

export default GenReportPrivate;
