import React, { useState } from "react";
import { toast } from "react-hot-toast";

import { DropdownMenuItem } from "~/components/ui/dropdown-menu";
import { Button } from "~/components/ui/button";
import { regenerateReport } from "~/api/query_fns/coverage-analysis";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import { marked } from "marked";
import { Message } from "./CustomMessages";
import logoImage from "../../public/images/logo.png";
import { FontStyle } from "jspdf-autotable";

// Add these variables at the beginning of your component or in a configuration object
const pdfConfig = {
  marginTop: 80,
  headerHeight: 60,
  logoTopOffset: 40,
  contentTopOffset: 30,
  decorativeLineOffset: 10,
  tableMarginTop: 80, // Adjust this value as needed
};

const baseUrl = import.meta.env.VITE_WEB_URL || "https://app.qumis.ai";

interface ReportMenuItemProps {
  reportId?: string;
  variant?: "menuItem" | "button";
  className?: string;
  markdownContent?: string;
  reportTitle?: string; // Add this line
  reportChatMessages?: Message[];
  documentNames?: string[];
}

// Add this type definition near the top of the file, after the imports
type AutoTableDidDrawCellData = {
  cursor: {
    y: number;
  } | null;
  cell: {
    y: number;
  };
};

const addLogoToPDF = (pdf: jsPDF, marginLeft: number) => {
  const logoWidth = 75;
  const logoHeight = 20;
  pdf.addImage(
    logoImage,
    "PNG",
    marginLeft,
    pdfConfig.logoTopOffset,
    logoWidth,
    logoHeight
  );
};

export const ReportMenuItem: React.FC<ReportMenuItemProps> = ({
  reportId,
  variant = "menuItem",
  className = "",
  markdownContent,
  reportTitle = "Report",
  reportChatMessages = [],
  documentNames = [],
}) => {
  // Add reportTitle parameter with default value
  const [isRegenerating, setIsRegenerating] = useState(false);
  const lastChatMessage =
    reportChatMessages?.length > 0
      ? reportChatMessages[reportChatMessages.length - 1]
      : null;

  const handleRegenerate = async () => {
    if (!reportId) return;
    try {
      setIsRegenerating(true);
      const response = await regenerateReport({ report_job_id: reportId });
      console.log("Report regeneration started:", response);
      window.location.reload();
    } catch (error) {
      console.error("Error regenerating report:", error);
      // You might want to show an error message to the user here
    } finally {
      setIsRegenerating(false);
    }
  };

  const handleExportPDF = async () => {
    if (!markdownContent && !reportChatMessages) return;

    const pdf: jsPDF = new jsPDF("p", "pt", "a4");
    const pageWidth: number = pdf.internal.pageSize.width;
    const pageHeight: number = pdf.internal.pageSize.height;
    const marginTop = pdfConfig.marginTop;
    const marginBottom = 60;
    const marginLeft = 60;
    const marginRight = 60;
    const contentWidth: number = pageWidth - marginLeft - marginRight;

    let currentY: number = marginTop + pdfConfig.contentTopOffset;

    // Add logo to the header
    try {
      addLogoToPDF(pdf, marginLeft);
    } catch (error) {
      console.error("Error adding logo to PDF:", error);
      // You might want to handle this error, e.g., by continuing without the logo
    }

    // Add a title page
    pdf.setFont("Helvetica", "bold");
    pdf.setFontSize(18);
    const titleY = pageHeight * 0.35; // 35% from the top

    // Split the title into multiple lines if it's too long
    const maxTitleWidth = pageWidth * 0.8; // 80% of page width
    const titleLines = pdf.splitTextToSize(reportTitle, maxTitleWidth);

    // Calculate the total height of the title
    const titleHeight =
      titleLines.length * pdf.getTextDimensions("Text").h * 1.5;

    // Center the title vertically within its space
    let currentTitleY = titleY - titleHeight / 2;

    // Draw each line of the title
    titleLines.forEach((line: string) => {
      pdf.text(line, pageWidth / 2, currentTitleY, { align: "center" });
      currentTitleY += pdf.getTextDimensions("Text").h * 1.5;
    });

    pdf.setFontSize(12);
    pdf.text(`Report ID: ${reportId}`, pageWidth / 2, currentTitleY + 20, {
      align: "center",
    });
    pdf.text(
      `Generated on: ${new Date().toLocaleString(undefined, {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      })}`,
      pageWidth / 2,
      currentTitleY + 40,
      { align: "center" }
    );

    // Add document names
    let lastY = currentTitleY + 60; // Initialize lastY after the date
    if (documentNames.length > 0) {
      pdf.text(
        "Files uploaded to generate report:",
        pageWidth / 2,
        lastY + 20,
        {
          align: "center",
        }
      );
      lastY += 35; // Move down for the document list

      // Define table data
      const tableData = documentNames.map((name, index) => [
        `${index + 1}`,
        name,
      ]);

      // Define table options
      let lastTableY = lastY;
      const tableOptions = {
        startY: lastY,
        head: [["#", "File Name"]],
        body: tableData,
        theme: "grid" as const,
        headStyles: {
          fillColor: [200, 200, 200] as [number, number, number],
          textColor: [0, 0, 0] as [number, number, number],
          fontStyle: "bold" as FontStyle,
        },
        bodyStyles: { textColor: [0, 0, 0] as [number, number, number] },
        columnStyles: { 0: { cellWidth: 20 } },
        margin: { left: marginLeft, right: marginRight },
        didDrawCell: (data: AutoTableDidDrawCellData) => {
          if (data.cursor) {
            lastTableY = Math.max(data.cursor.y, lastTableY);
          }
        },
      };

      // Add the table to the PDF
      autoTable(pdf, tableOptions);

      // Update lastY to be after the table
      lastY = lastTableY + 10;

      pdf.setTextColor(0); // Reset text color to black
    }

    pdf.setFontSize(12);
    pdf.setTextColor(0, 0, 255); // Set text color to blue for the link
    pdf.textWithLink(`View Report Online`, pageWidth / 2, lastY + 40, {
      align: "center",
      url: `${baseUrl}/report/analysis/${reportId}${
        lastChatMessage ? `/chat/${lastChatMessage.id}` : ""
      }`,
    });

    // Add a red disclaimer 3/4 down the page
    pdf.setTextColor(255, 0, 0); // Set text color to red
    pdf.setFontSize(10);
    pdf.setFont("Helvetica", "italic");
    const disclaimerY = pageHeight * 0.75; // 75% from the top
    const disclaimer =
      "[Disclaimer: This report and its content was generated by the Qumis Cortex using artificial intelligence. While great efforts have been made to ensure its quality and accuracy, we cannot guarantee its accuracy or completeness. We encourage you to verify the information independently before making decisions based on this content and seek professional guidance if needed. Qumis does not assume any responsibility or liability for the use or interpretation of this content.]";
    const disclaimerLines = pdf.splitTextToSize(disclaimer, contentWidth);
    pdf.text(disclaimerLines, pageWidth / 2, disclaimerY, { align: "center" });
    pdf.setTextColor(0); // Reset text color to black
    pdf.addPage();

    const addTextToPDF = (
      text: string,
      x: number,
      y: number,
      maxWidth: number
    ): number => {
      const textLines: string[] = pdf.splitTextToSize(text, maxWidth);
      pdf.text(textLines, x, y);
      return y + textLines.length * pdf.getTextDimensions("Text").h * 1.5; // Increased line spacing
    };

    const addElementToPDF = (element: HTMLElement, level = 0): void => {
      const addNewPageIfNeeded = (requiredSpace = 50) => {
        if (currentY + requiredSpace > pageHeight - marginBottom) {
          pdf.addPage();
          currentY = marginTop + pdfConfig.contentTopOffset;
        }
      };

      const headingSizes = {
        H1: { fontSize: 24, spacing: 11.25 },
        H2: { fontSize: 18, spacing: 9.375 },
        H3: { fontSize: 15, spacing: 7.5 },
        H4: { fontSize: 12, spacing: 5.625 },
        H5: { fontSize: 10.5, spacing: 3.75 },
        H6: { fontSize: 9, spacing: 1.875 },
      };

      switch (element.tagName) {
        case "H1":
        case "H2":
        case "H3":
        case "H4":
        case "H5":
        case "H6": {
          const { fontSize, spacing } =
            headingSizes[element.tagName as keyof typeof headingSizes];
          addNewPageIfNeeded(fontSize + spacing);
          pdf.setFont("Helvetica", "bold");
          pdf.setFontSize(fontSize);
          currentY = addTextToPDF(
            element.textContent || "",
            marginLeft,
            currentY,
            contentWidth
          );
          currentY += spacing;
          break;
        }
        case "P": {
          addNewPageIfNeeded(40);
          pdf.setFont("Helvetica", "normal");
          pdf.setFontSize(11);
          currentY = addTextToPDF(
            element.textContent || "",
            marginLeft,
            currentY,
            contentWidth
          );
          currentY += 15;
          break;
        }
        case "UL":
        case "OL": {
          Array.from(element.children).forEach((li: Element, index: number) => {
            addNewPageIfNeeded(20);
            pdf.setFont("Helvetica", "normal");
            pdf.setFontSize(11);
            const bullet = element.tagName === "OL" ? `${index + 1}.` : "•";
            const indent = level * 10;

            const liText = Array.from(li.childNodes)
              .filter(
                (node) =>
                  node.nodeType === Node.TEXT_NODE ||
                  (node.nodeType === Node.ELEMENT_NODE &&
                    (node as Element).tagName !== "UL" &&
                    (node as Element).tagName !== "OL")
              )
              .map((node) => {
                if (node.nodeType === Node.TEXT_NODE) {
                  return (
                    node.textContent?.replace(/^[•\-*]\s*/, "").trim() || ""
                  );
                } else {
                  return (node as Element).textContent?.trim() || "";
                }
              })
              .join(" ")
              .trim();

            currentY = addTextToPDF(
              `${bullet} ${liText}`,
              marginLeft + 10 + indent,
              currentY,
              contentWidth - 10 - indent
            );

            const sublist = li.querySelector("ul, ol");
            if (sublist) {
              addElementToPDF(sublist as HTMLElement, level + 1);
            } else {
              currentY += 1;
            }
          });
          currentY += 1;
          break;
        }
        case "BLOCKQUOTE": {
          addNewPageIfNeeded(50);
          pdf.setFont("Helvetica", "italic");
          pdf.setFontSize(11);
          pdf.setTextColor(100, 100, 100);
          currentY = addTextToPDF(
            element.textContent || "",
            marginLeft + 20,
            currentY,
            contentWidth - 40
          );
          pdf.setTextColor(0, 0, 0);
          currentY += 20;
          break;
        }
        case "TABLE": {
          const tableData: string[][] = Array.from(
            element.querySelectorAll("tr")
          ).map((row) =>
            Array.from(row.querySelectorAll("th, td")).map(
              (cell) => cell.textContent || ""
            )
          );
          const headers: string[] = tableData.shift() || [];

          let tableMarginTop =
            currentY === marginTop + pdfConfig.contentTopOffset
              ? pdfConfig.tableMarginTop
              : marginTop + pdfConfig.headerHeight;

          if (currentY + 30 > pageHeight - marginBottom) {
            pdf.addPage();
            currentY = marginTop + pdfConfig.contentTopOffset;
            tableMarginTop = pdfConfig.tableMarginTop;
          }

          autoTable(pdf, {
            head: [headers],
            body: tableData,
            startY: currentY,
            margin: {
              top: tableMarginTop,
              right: marginRight,
              bottom: marginBottom,
              left: marginLeft,
            },
            tableWidth: contentWidth,
            styles: {
              cellPadding: 5,
              fontSize: 10,
              lineColor: [200, 200, 200],
              lineWidth: 0.1,
              fillColor: [255, 255, 255],
            },
            headStyles: {
              fontStyle: "bold",
              lineColor: [200, 200, 200],
              lineWidth: 0.1,
              fillColor: [255, 255, 255],
              textColor: [0, 0, 0],
            },
            bodyStyles: {
              lineColor: [200, 200, 200],
              lineWidth: 0.1,
              fillColor: [255, 255, 255],
            },
            alternateRowStyles: {},
            didDrawPage: (data) => {
              addLogoToPDF(pdf, marginLeft);
              pdf.setDrawColor(0, 0, 0);
              pdf.setLineWidth(2);
              pdf.line(
                marginLeft,
                marginTop - pdfConfig.decorativeLineOffset,
                pageWidth - marginRight,
                marginTop - pdfConfig.decorativeLineOffset
              );

              currentY = data.cursor?.y ?? currentY;
              tableMarginTop = marginTop + pdfConfig.headerHeight;
            },
          });
          currentY += 20;
          break;
        }
        case "IMG": {
          addNewPageIfNeeded(200);
          const img = element as HTMLImageElement;
          const imgWidth = Math.min(img.width, contentWidth);
          const imgHeight = (img.height * imgWidth) / img.width;
          pdf.addImage(
            img.src,
            "JPEG",
            marginLeft,
            currentY,
            imgWidth,
            imgHeight
          );
          currentY += imgHeight + 20;
          break;
        }
        case "HR": {
          addNewPageIfNeeded(30);
          pdf.setDrawColor(200, 200, 200);
          pdf.setLineWidth(0.5);
          pdf.line(marginLeft, currentY, pageWidth - marginRight, currentY);
          currentY += 20;
          break;
        }
        default: {
          addNewPageIfNeeded(30);
          pdf.setFont("Helvetica", "normal");
          pdf.setFontSize(11);
          currentY = addTextToPDF(
            element.textContent || "",
            marginLeft,
            currentY,
            contentWidth
          );
          currentY += 10;
        }
      }
    };

    // Add markdown content if available
    if (markdownContent) {
      const htmlContent: string = await marked(markdownContent);
      const tempDiv: HTMLDivElement = document.createElement("div");
      tempDiv.innerHTML = htmlContent;
      tempDiv.classList.add("copilotKitMarkdown");

      Array.from(tempDiv.children).forEach((element: Element) => {
        addElementToPDF(element as HTMLElement, 0);
      });
    }

    // Add chat messages if available
    if (reportChatMessages && reportChatMessages.length > 0) {
      pdf.addPage();
      currentY = marginTop + pdfConfig.contentTopOffset;

      pdf.setFont("Helvetica", "bold");
      pdf.setFontSize(18);
      currentY = addTextToPDF(
        "Chat History",
        marginLeft,
        currentY,
        contentWidth
      );
      currentY += 20;

      reportChatMessages.forEach((message: Message) => {
        // Check if there's enough space for the message header and at least one line of content
        if (currentY + 40 > pageHeight - marginBottom) {
          pdf.addPage();
          currentY = marginTop + pdfConfig.contentTopOffset;
        }

        pdf.setFont("Helvetica", "bold");
        pdf.setFontSize(12);
        const roleText =
          message.role === "assistant"
            ? "Qumis Clerk"
            : message.role.charAt(0).toUpperCase() + message.role.slice(1);
        currentY = addTextToPDF(
          `${roleText}:`,
          marginLeft,
          currentY,
          contentWidth
        );
        currentY += 5;

        pdf.setFont("Helvetica", "normal");
        pdf.setFontSize(11);
        const messageLines = pdf.splitTextToSize(
          message.content,
          contentWidth - 10
        );

        messageLines.forEach((line: string) => {
          if (currentY + 15 > pageHeight - marginBottom) {
            pdf.addPage();
            currentY = marginTop + pdfConfig.contentTopOffset;
          }
          currentY = addTextToPDF(
            line,
            marginLeft + 10,
            currentY,
            contentWidth - 10
          );
        });

        currentY += 15;
      });
    }

    // Add page numbers and decorative elements
    const pageCount = pdf.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      pdf.setPage(i);
      addLogoToPDF(pdf, marginLeft);
      pdf.setFont("Helvetica", "normal");
      pdf.setFontSize(7); // Changed from 10 to 8
      pdf.setTextColor(128, 128, 128); // Gray color for page numbers

      // Split the footer into two lines
      pdf.text(
        "***This report was generated by Qumis using artificial intelligence and may contain errors and/or inaccuracies.",
        pageWidth / 2,
        pageHeight - 25,
        { align: "center" }
      );
      pdf.text(
        "We strongly encourage verifying the information independently before making decisions based on this content and seeking professional guidance if necessary***",
        pageWidth / 2,
        pageHeight - 15,
        { align: "center" }
      );

      pdf.text(`Page ${i} of ${pageCount}`, pageWidth / 2, pageHeight - 35, {
        align: "center",
      });

      // Add decorative line at the top of each page
      pdf.setDrawColor(0, 0, 0);
      pdf.setLineWidth(2);
      pdf.line(
        marginLeft,
        marginTop - pdfConfig.decorativeLineOffset,
        pageWidth - marginRight,
        marginTop - pdfConfig.decorativeLineOffset
      );
    }

    // Add "Report End" to a new page at the end
    pdf.addPage();
    pdf.setFont("Helvetica", "bold");
    pdf.setFontSize(24);
    pdf.setTextColor(0, 0, 0); // Black color for "Report End"
    pdf.text("Report End.", pageWidth / 2, pageHeight / 2, {
      align: "center",
      baseline: "middle",
    });

    pdf.save(`${reportTitle}.pdf`);
  };

  const handleCopyShareableLink = () => {
    const shareableUrl = `${baseUrl}/report/analysis/${reportId}${
      lastChatMessage ? `/chat/${lastChatMessage.id}` : ""
    }`;
    navigator.clipboard
      .writeText(shareableUrl)
      .then(() => {
        toast.success("Shareable link copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy link: ", err);
        toast.error("Failed to copy link. Please try again.");
      });
  };

  const content = (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="mr-2"
      >
        <path d="M21.5 2v6h-6M2.5 22v-6h6M2 11.5a10 10 0 0 1 18.8-4.3M22 12.5a10 10 0 0 1-18.8 4.3" />
      </svg>
      {isRegenerating ? "Regenerating..." : "Re-Generate Report"}
    </>
  );

  const exportContent = (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="mr-2"
      >
        <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
        <polyline points="7 10 12 15 17 10" />
        <line x1="12" y1="15" x2="12" y2="3" />
      </svg>
      Export PDF
    </>
  );

  const copyLinkContent = (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="mr-2"
      >
        <path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71" />
        <path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71" />
      </svg>
      Copy Shareable Link
    </>
  );

  if (variant === "button") {
    return (
      <>
        <Button
          onClick={handleRegenerate}
          disabled={isRegenerating}
          className={className}
        >
          {content}
        </Button>
        <Button onClick={handleExportPDF} className={className}>
          {exportContent}
        </Button>
        <Button onClick={handleCopyShareableLink} className={className}>
          {copyLinkContent}
        </Button>
      </>
    );
  }

  return (
    <>
      <DropdownMenuItem onClick={handleRegenerate} disabled={isRegenerating}>
        {content}
      </DropdownMenuItem>
      <DropdownMenuItem onClick={handleExportPDF}>
        {exportContent}
      </DropdownMenuItem>
      <DropdownMenuItem onClick={handleCopyShareableLink}>
        {copyLinkContent}
      </DropdownMenuItem>
    </>
  );
};
