import { NavLink } from "react-router-dom";
import { useState, useEffect } from "react";
import WelcomePopup from "~/components/WelcomePopup";
import useSession from "~/hooks/useSession";

const LandingPage = () => {
  const [showWelcomePopup, setShowWelcomePopup] = useState(false);
  const { session } = useSession();

  useEffect(() => {
    if (session && session.email) {
      const hasSeenWelcome = sessionStorage.getItem("hasSeenWelcome");
      if (!hasSeenWelcome) {
        setShowWelcomePopup(true);
      }
    }
  }, [session]);

  const handleClosePopup = () => {
    setShowWelcomePopup(false);
    sessionStorage.setItem("hasSeenWelcome", "true");
  };

  return (
    <div className="flex h-[85vh] flex-col items-center justify-center">
      <div className="flex flex-col items-center justify-center">
        <img
          src="/images/Qumis_FullColor_Logomark_RGB.png"
          alt="Qumis"
          className="my-1 h-16 py-1"
        />
        <h1 className="text-black-100 mb-20 text-3xl font-bold">
          How can I help you today ?
        </h1>
        <div className="mb-4 flex flex-row justify-center space-x-4">
          <NavLink
            to="/chat"
            className="inline-flex h-10 w-60 flex-1 items-center justify-center rounded-md border border-black bg-white px-4 py-2 text-sm font-medium text-black ring-offset-background transition-colors hover:bg-black hover:text-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50"
          >
            Policy Chat
          </NavLink>
          <NavLink
            to="/policy-comparison"
            className="inline-flex h-10 w-60 flex-1 items-center justify-center rounded-md border border-black bg-white px-4 py-2 text-sm font-medium text-black ring-offset-background transition-colors hover:bg-black hover:text-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50"
          >
            Policy Comparison
          </NavLink>
        </div>
        <div className="flex flex-row justify-center space-x-4">
          <NavLink
            to="/claim-coverage"
            className="inline-flex h-10 w-60 flex-1 items-center justify-center rounded-md border border-black bg-white px-4 py-2 text-sm font-medium text-black ring-offset-background transition-colors hover:bg-black hover:text-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50"
          >
            Claim Coverage
          </NavLink>
          <NavLink
            to="/generic-report"
            className="inline-flex h-10 w-60 flex-1 items-center justify-center rounded-md border border-black bg-white px-4 py-2 text-sm font-medium text-black ring-offset-background transition-colors hover:bg-black hover:text-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50"
          >
            Custom Report
          </NavLink>
        </div>
      </div>
      <WelcomePopup open={showWelcomePopup} onClose={handleClosePopup} />
    </div>
  );
};

export default LandingPage;
