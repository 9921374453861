import { useMutation } from "react-query";
import { postChatMessage } from "~/api/query_fns/coverage-analysis";
import { SingleDocResponseData } from "~/api/query_fns/documents";
import { CustomChatInput } from "~/components/CustomChatInput";
import {
  CustomMessagesWithReference,
  Message,
} from "~/components/CustomMessages";
import { openReferenceType, ReportSourceType } from "~/doc-util";
import { MessagesProps } from "~/components/chat/copilotkit-utils";
import React, { useState, useMemo, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { CopilotModalProps, CopilotModal } from "./chat/Modal";

interface ReportChatProps {
  context: string;
  initialMessage: string;
  reportKey: string;
  documents: SingleDocResponseData[];
  openReference: openReferenceType;
  reportSource: ReportSourceType;
  userId?: string;
  reportId?: string;
  reportSourcePostFix?: string;
  keyId?: string;
  children?: React.ReactNode;
  appendToReportChatMessages?: (messages: Message[]) => void;
  initialChatMessages?: Message[];
}

const CustomChat = ({
  context,
  initialMessage,
  reportId,
  documents,
  userId,
  reportKey,
  openReference,
  reportSource,
  children,
  appendToReportChatMessages,
  initialChatMessages = [],
}: ReportChatProps) => {
  const postMessageMutation = useMutation(postChatMessage);
  const [isCopilotOpen, setIsCopilotOpen] = useState(true);

  const chatId = useMemo(() => {
    if (initialChatMessages && initialChatMessages.length > 0) {
      return initialChatMessages[0].chatId || uuidv4();
    }
    return uuidv4();
  }, [initialChatMessages]);

  useEffect(() => {
    console.log("!!!!!!!!!!!!!!!!chatId changed:", chatId);
  }, [chatId]);

  const [ratings, setRatings] = useState<Record<string, number>>({});

  return (
    <div
      className={`copilotKitSidebarContentWrapper ${
        isCopilotOpen ? "sidebarExpanded" : ""
      }`}
    >
      <CopilotSidebar
        clickOutsideToClose={false}
        labels={{
          title: "Qumis Clerk",
          initial: initialMessage,
        }}
        defaultOpen={true}
        makeSystemMessage={() => context}
        initialChatMessages={initialChatMessages}
        Messages={(props: MessagesProps) => (
          <CustomMessagesWithReference
            {...props}
            openReference={openReference}
            ratings={ratings}
            setRatings={setRatings}
          />
        )}
        onSetOpen={(open) => setIsCopilotOpen(open)}
        className="py-6 pt-8 "
        Input={CustomChatInput}
        chatId={chatId}
        onSubmitMessage={(message: Message) => {
          console.log("onSubmitMessageonSubmitMessage", chatId);
          postMessageMutation.mutate({
            id: message.id,
            report_source: reportSource,
            message: message.content,
            report_id: reportId,
            document_ids: documents.map((doc) => doc.document.id).join(","),
            user_id: userId,
            report_key: reportKey,
            chat_id: chatId,
            message_type: "user",
          });
        }}
        onResponseComplete={(currentUserMessage, currentAIMessage) => {
          console.log("onResponseCompleteonResponseComplete", chatId);
          console.log("currentUserMessage", currentUserMessage);
          console.log("currentAIMessage", currentAIMessage);
          postMessageMutation.mutate(
            {
              id: currentAIMessage.id,
              report_source: reportSource,
              message: currentAIMessage.content,
              report_id: reportId,
              document_ids: documents.map((doc) => doc.document.id).join(","),
              user_id: userId,
              report_key: reportKey,
              chat_id: chatId,
              message_type: "assistant",
            },
            {
              onSuccess: (response) => {
                console.log("Message posted successfully:", response);
                if (appendToReportChatMessages) {
                  appendToReportChatMessages([
                    {
                      id: currentUserMessage.id,
                      content: currentUserMessage.content,
                      role: "user",
                      isVisible: true,
                    },
                    {
                      id: currentAIMessage.id,
                      content: currentAIMessage.content,
                      role: "assistant",
                      isVisible: true,
                    },
                  ]);
                }
              },
            }
          );
        }}
      >
        {children}
      </CopilotSidebar>
    </div>
  );
};

export function CopilotSidebar(props: CopilotModalProps) {
  props = {
    ...props,
    className: props.className
      ? props.className + " copilotKitSidebar"
      : "copilotKitSidebar",
  };
  const [expandedClassName, setExpandedClassName] = useState(
    props.defaultOpen ? "sidebarExpanded" : ""
  );

  const onSetOpen = (open: boolean) => {
    props.onSetOpen?.(open);
    setExpandedClassName(open ? "sidebarExpanded" : "");
  };

  return (
    <div className={`copilotKitSidebarContentWrapper ${expandedClassName}`}>
      <CopilotModal {...props} {...{ onSetOpen }}>
        {props.children}
      </CopilotModal>
    </div>
  );
}

export { CustomChat };
