import { useState, type FC } from "react";

import "@milkdown/prose/view/style/prosemirror.css";
import "@milkdown/prose/tables/style/tables.css";
import "@milkdown/theme-nord/style.css";
import "~/../public/texteditor.css";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "~/components/ui/dropdown-menu";

import { TriangleUpIcon, TriangleDownIcon } from "@radix-ui/react-icons";
import { CustomMarkdown } from "./CustomMarkdown";
import { openReferenceType } from "~/doc-util";

export function GenActions({ style }: { style?: React.CSSProperties }) {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <div className={"mb-10"} style={style}>
      <DropdownMenu onOpenChange={toggleOpen}>
        <DropdownMenuTrigger className="border-black-300 rounded border-b px-2 py-2">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              borderBottom: "0px solid lightgrey",
              padding: "7px",
            }}
          >
            {isOpen ? (
              <TriangleUpIcon height={"20"} width={"20px"} />
            ) : (
              <TriangleDownIcon height={"20"} width={"20px"} />
            )}
            <span>ACTIONS</span>
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent side="bottom" align="end">
          <DropdownMenuLabel>Generate</DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuItem>Reservation Of Rights Letter</DropdownMenuItem>
          <DropdownMenuItem>Denial Letter</DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuLabel>General</DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuItem>Request External Counsel</DropdownMenuItem>
          <DropdownMenuItem>Close Claim</DropdownMenuItem>
          <DropdownMenuItem>Audit History</DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}

/* 
import { Milkdown, useEditor } from '@milkdown/react'
import { defaultValueCtx, Editor, rootCtx } from '@milkdown/core';
import { commonmark } from '@milkdown/preset-commonmark';
import { nord } from '@milkdown/theme-nord';
import { gfm } from '@milkdown/preset-gfm';

export const CoverageReportEditor: FC<{markdown: string}> = ({markdown}) => {
  useEditor((root) => {
    return Editor
      .make()
      .config(ctx => {
        ctx.set(rootCtx, root)
        ctx.set(defaultValueCtx, markdown)
      })
      .config(nord)
      .use(commonmark)
      .use(gfm)
  }, [])

  return (
  <div className="prose">
  <Milkdown />
  </div>)
} 
}*/

export const CoverageReportEditor: FC<{
  markdown: string;
  openReference: openReferenceType;
}> = ({ markdown, openReference }) => {
  return (
    <div className="prose prose-stone">
      <CustomMarkdown
        content={markdown}
        openReference={openReference}
        maxHeight="72vh"
        className="copilotKitMarkdown p-4"
      />
    </div>
  );
};

/*
Good Examples:
https://codesandbox.io/p/sandbox/milkdown-custom-syntax-mudgd?file=%2Fsrc%2Findex.ts%3A102%2C13
https://codesandbox.io/p/devbox/milkdown-editor-example-cxxuw6?file=%2Fsrc%2Fcomponents%2Feditor.jsx%3A14%2C1
*/
