import React, { useState } from "react";
import { Worker } from "@react-pdf-viewer/core";
import { MilkdownProvider } from "@milkdown/react";
import { StrictMode } from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
} from "~/components/ui/card";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "~/components/ui/tabs";
import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from "~/components/ui/accordion";
import { Input } from "~/components/ui/input";
import { CoverageReportEditor } from "./CoverageReportEditor";
import MainDocument from "~/routes/GenPrecedentSearch/MainDocument";
import { SingleDocResponseData } from "~/api/query_fns/documents";
import { openReferenceType } from "~/doc-util";
import { Button as ShadcnButton } from "~/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "~/components/ui/dropdown-menu";
import { MoreHorizontal } from "lucide-react";
import { ReportMenuItem } from "./ReportMenu";
import { Message } from "../components/CustomMessages";

export function ReportTabs({
  reportId,
  reportName,
  fullCoverageReport,
  docs,
  openReference,
  reportChatMessages,
  documentNames,
}: {
  reportId?: string;
  reportName: string;
  fullCoverageReport: string;
  docs: SingleDocResponseData[];
  openReference: openReferenceType;
  reportChatMessages: Message[];
  documentNames?: string[];
}) {
  const [editableReportName, setEditableReportName] = useState(reportName);
  const [isEditing, setIsEditing] = useState(false);

  const handleDoubleClick = () => setIsEditing(true);
  const handleBlur = () => setIsEditing(false);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setEditableReportName(e.target.value);

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
      <Tabs
        defaultValue="coverageReport"
        className="h-full min-h-[75vh] w-full"
      >
        <div className="custom-heading-darker custom-shadcn-components-sharpe  p-3 px-4">
          {isEditing ? (
            <Input
              value={editableReportName}
              onChange={handleChange}
              onBlur={handleBlur}
              autoFocus
              className="text-xl "
            />
          ) : (
            <h2
              className="text-black-500 text-xl font-semibold"
              onDoubleClick={handleDoubleClick}
            >
              {editableReportName || "Report Name - Empty"}
            </h2>
          )}

          <div className="flex items-center">
            <TabsList className="mt-5 ">
              <TabsTrigger value="coverageReport">Report</TabsTrigger>
              <TabsTrigger value="docs">Documents</TabsTrigger>
            </TabsList>
            {reportId && (
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <ShadcnButton variant="outline" className="ml-auto">
                    <MoreHorizontal className="h-4 w-4" />
                  </ShadcnButton>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <ReportMenuItem
                    reportId={reportId}
                    markdownContent={fullCoverageReport}
                    reportChatMessages={reportChatMessages}
                    reportTitle={reportName}
                    documentNames={documentNames}
                  />
                </DropdownMenuContent>
              </DropdownMenu>
            )}
          </div>
        </div>

        <TabsContent
          className="custom-shadcn-components-sharpe h-[75vh] w-full overflow-y-auto"
          value="coverageReport"
        >
          <div className="w-full">
            <StrictMode>
              <MilkdownProvider>
                <div className="flex w-full flex-col overflow-scroll">
                  <CoverageReportEditor
                    markdown={fullCoverageReport}
                    openReference={openReference}
                  />
                </div>
              </MilkdownProvider>
            </StrictMode>
          </div>
        </TabsContent>
        <TabsContent
          value="docs"
          className="custom-heading-darker custom-shadcn-components-sharpe h-[75vh] w-full overflow-y-auto"
        >
          <div className="w-full p-4">
            {docs.length > 1 ? (
              <DocumentAccordion docs={docs} />
            ) : (
              <DocumentCard doc={docs[0]} />
            )}
          </div>
        </TabsContent>
      </Tabs>
    </Worker>
  );
}

interface DocumentAccordionProps {
  docs: SingleDocResponseData[];
}

interface DocumentCardProps {
  doc: SingleDocResponseData;
}

const DocumentCard: React.FC<DocumentCardProps> = ({ doc }) => (
  <Card>
    <CardHeader>
      <CardDescription>{`Filename: ${doc.document.filename}`}</CardDescription>
    </CardHeader>
    <CardContent>
      <MainDocument
        presignedUrl={doc.presignedUrl}
        persistHighlight={() => null}
        hideDocument={false}
        highlightedContent={""}
      />
    </CardContent>
  </Card>
);

const DocumentAccordion: React.FC<DocumentAccordionProps> = ({ docs }) => (
  <Accordion type="single" collapsible className="overflow-scroll">
    {docs.map((doc, index) => (
      <AccordionItem key={index} value={`item-${index}`}>
        <AccordionTrigger>
          <CardDescription>{`Filename: ${doc.document.filename}`}</CardDescription>
        </AccordionTrigger>
        <AccordionContent>
          <MainDocument
            presignedUrl={doc.presignedUrl}
            persistHighlight={() => null}
            hideDocument={false}
            highlightedContent={""}
          />
        </AccordionContent>
      </AccordionItem>
    ))}
  </Accordion>
);
