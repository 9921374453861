/**
 * v0 by Vercel.
 * @see https://v0.dev/t/bz8QpogGrc1
 * Documentation: https://v0.dev/docs#integrating-generated-code-into-your-nextjs-app
 */
import { useState } from "react";
import { Button } from "~/components/ui/button";
import React from "react";
import { Link } from "react-router-dom";
import { Heading } from "~/components/ui/heading";

interface Step {
  description: string;
  number: number;
  label: string;
  isActive?: boolean;
  component?: React.ReactElement;
  isMandatory?: boolean;
  isComplete?: boolean;
}

interface ComponentProps {
  steps: Step[];
  title: string;
  lastStepName: string;
  onFinalStep: () => void;
  onCancelPath: string;
}

export function StepReportComponent({
  steps,
  title,
  lastStepName,
  onFinalStep,
  onCancelPath,
}: ComponentProps) {
  const [currentStep, setCurrentStep] = useState(
    steps.find((step) => step.isActive)?.number || 1
  );

  const handleNext = () => {
    const currentStepData = steps.find((step) => step.number === currentStep);
    if (currentStepData?.isMandatory && !currentStepData.isComplete) {
      // Don't proceed if the current step is mandatory and not complete
      return;
    }

    if (currentStep === steps.length) {
      onFinalStep(); // Call the final step action
    } else {
      setCurrentStep((prev) => Math.min(prev + 1, steps.length));
    }
  };

  const handlePrevious = () => {
    setCurrentStep((prev) => Math.max(prev - 1, 1));
  };

  const currentStepData = steps.find((step) => step.number === currentStep);
  const currentStepLabel = currentStepData?.label || "";
  const CurrentStepComponent = currentStepData?.component || null;

  return (
    <div className="custom-shadcn-components flex min-h-[75vh] flex-col">
      <header className="flex items-center justify-between border-b p-4">
        <Heading as="h2" className="text-xl font-semibold">
          {title}
        </Heading>
        <Link to={onCancelPath} className="text-gray-500 hover:text-gray-700">
          {CloseIcon()}
        </Link>
      </header>

      <div className="flex flex-grow overflow-hidden">
        <nav className="w-64 flex-shrink-0 border-r p-4">
          {steps.map((step) => (
            <div
              key={step.number}
              className={`flex items-center gap-2 p-2 ${
                step.number === currentStep ? "rounded-md bg-gray-100" : ""
              }`}
            >
              <div className="flex h-6 w-6 items-center justify-center rounded-full border text-sm font-medium">
                {step.number}
              </div>
              <span className="text-sm">{step.label}</span>
            </div>
          ))}
        </nav>
        <main className="flex flex-grow flex-col overflow-auto p-10">
          <h2 className="mb-2 text-xl font-semibold">{currentStepLabel}</h2>
          <p className="mb-4 text-sm text-gray-600">
            {currentStepData?.description}
          </p>
          <div className="flex-grow">
            {CurrentStepComponent && React.cloneElement(CurrentStepComponent)}
          </div>
        </main>
      </div>

      <footer className="flex justify-end gap-2 p-4">
        {currentStep > 1 && (
          <Button
            variant="default"
            onClick={handlePrevious}
            className="border border-black bg-white text-black hover:bg-black/90 hover:text-white"
          >
            Previous
          </Button>
        )}
        <Button
          variant="default"
          onClick={handleNext}
          disabled={currentStepData?.isMandatory && !currentStepData.isComplete}
          className="bg-black text-white hover:bg-black/80"
        >
          {currentStep === steps.length ? lastStepName : "Next"}
        </Button>
      </footer>
    </div>
  );
}

const CloseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 50 50"
    width="25px"
    height="20px"
    fill="white"
  >
    <path d="M 7.71875 6.28125 L 6.28125 7.71875 L 23.5625 25 L 6.28125 42.28125 L 7.71875 43.71875 L 25 26.4375 L 42.28125 43.71875 L 43.71875 42.28125 L 26.4375 25 L 43.71875 7.71875 L 42.28125 6.28125 L 25 23.5625 Z" />
  </svg>
);
