import React, { useState, useEffect } from "react";
import { SingleDocResponseData } from "~/api/query_fns/documents";
import { StepReportComponent } from "../components/StepReportComponent";
import UploadArea from "./Documents/UploadArea";
import { useGenerateReportNew } from "~/hooks/useGenerateReportNew";
import { generateQueryExpansionsReportLLMService } from "~/api/query_fns/query-expansion";
import { LoadingComponent } from "~/utils/util-report";
import {
  headingsPlugin,
  listsPlugin,
  quotePlugin,
  thematicBreakPlugin,
  markdownShortcutPlugin,
  MDXEditor,
  toolbarPlugin,
  tablePlugin,
  ListsToggle,
} from "@mdxeditor/editor";
import {
  UndoRedo,
  BoldItalicUnderlineToggles,
  BlockTypeSelect,
  InsertTable,
} from "@mdxeditor/editor";
import "@mdxeditor/editor/style.css";

import { WandIcon } from "lucide-react";
import { cn } from "~/utils";

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "~/components/ui/select";

interface ReportDescriptionProps {
  additionalNotes: string;
  setAdditionalNotes: (notes: string) => void;
}

export default function UCPolicyComparisonNew() {
  const {
    isLoading,
    handleGenerateReport,
    additionalNotes,
    setAdditionalNotes,
    uploadedDocs,
    handleUploadComplete,
    reportTitle,
    setReportTitle,
  } = useGenerateReportNew("generic_report");

  if (isLoading) {
    return <LoadingComponent title="GENERATING CUSTOM REPORT" />;
  }

  return (
    <StepReportComponent
      title="Generate Custom Report"
      lastStepName="Generate Custom Report"
      steps={[
        {
          number: 1,
          label: "Report Title",
          description: "Please provide a title for this report",
          component: (
            <ReportTitle
              reportTitle={reportTitle}
              setReportTitle={setReportTitle}
            />
          ),
        },
        {
          number: 2,
          label: "Description",
          description:
            "Please type in a detailed description of exactly what you want to receive in this report. For best results, please clearly describe the task you would like the report to accomplish, the context or details needed to accomplish that text, and the desired format for the output. For example, “You will be provided with two PDF documents: an expiring property and casualty insurance policy and its corresponding renewal quote. [Context] Analyze the two documents to identify and compare key policy provisions. [Task] Present the findings in a clear and concise format using bullet points to summarize key changes followed by detailed explanations for each point. [Format].”",
          component: (
            <ReportDescription
              additionalNotes={additionalNotes}
              setAdditionalNotes={setAdditionalNotes}
            />
          ),
        },
        {
          number: 3,
          label: "Documents",
          description: "Upload any documents relevant for the report",
          component: (
            <ReportDocuments
              uploadedDocs={uploadedDocs}
              handleUploadComplete={handleUploadComplete}
            />
          ),
        },
      ]}
      onFinalStep={handleGenerateReport}
      onCancelPath="/generic-report"
    />
  );
}

interface ReportTitleProps {
  reportTitle: string;
  setReportTitle: (title: string) => void;
}

export const ReportTitle: React.FC<ReportTitleProps> = ({
  reportTitle,
  setReportTitle,
}) => {
  return (
    <input
      type="text"
      className="w-1/2 border border-gray-300 p-2 "
      placeholder="Enter report title here...E.g. Policy Comparison"
      value={reportTitle}
      onChange={(e) => setReportTitle(e.target.value)}
    />
  );
};

const ReportDescription: React.FC<ReportDescriptionProps> = ({
  additionalNotes,
  setAdditionalNotes,
}) => {
  const [isWandActive, setIsWandActive] = useState(false);
  const [localNotes, setLocalNotes] = useState(additionalNotes);
  const [forceUpdate, setForceUpdate] = useState(0);
  const showWandButton = false;

  // Add this array of prompt options
  const promptOptions = [
    { name: "Select A Report Template", description: "" },
    {
      name: "Quote Renewal",
      description:
        "You will be provided with two PDF documents: an expiring property and casualty insurance policy and its corresponding renewal quote. Analyze the two documents to identify and compare key policy provisions. Generate a detailed report highlighting the changes between the expiring policy and the renewal quote. Focus on significant modifications to coverages, limits, deductibles, premiums, and any new or removed provisions. Present the findings in a clear and concise format using bullet points to summarize key changes followed by detailed explanations for each point. Adopt the perspective of a knowledgeable insurance broker providing information to a client. Maintain an objective and informative tone. Assume the role of an experienced property and casualty insurance broker analyzing policy changes for a client.",
    },
    {
      name: "Spec Sheet Comparison",
      description:
        "You will be provided with two documents: a specimen insurance policy or coverage checklist and an uploaded insurance policy. Conduct a meticulous, word-for-word comparison between the provided specimen policy or coverage checklist and the uploaded insurance policy. Analyze all policy elements indicated in either the specimen or checklist to identify exact matches, missing or modified language, and additional coverages or language present in the uploaded policy. Present the comparison results in a highly detailed format, clearly indicating: Exact matching language between the two documents, including specific policy sections and subsections. Instances of missing or modified language in the uploaded policy, providing precise references to the specimen policy or checklist. Additional coverages or language present in the uploaded policy but absent in the specimen or checklist, with detailed descriptions.",
    },
  ];

  useEffect(() => {
    setLocalNotes(additionalNotes);
  }, [additionalNotes]);

  const handleWandClick = async () => {
    console.log("handleWandClick started");
    setIsWandActive(true);

    try {
      console.log("Calling generateQueryExpansionsReportLLMService with:", {
        additionalNotes: localNotes,
      });
      const response = await generateQueryExpansionsReportLLMService({
        user_request: localNotes,
        documents: [],
      });
      console.log(
        "Response from generateQueryExpansionsReportLLMService:",
        response
      );

      const newNotes =
        response.report_interpreter_analysis.improved_request ||
        "No expanded query available";
      console.log("Setting additionalNotes to:", newNotes);
      setLocalNotes(newNotes);
      setAdditionalNotes(newNotes);
      setForceUpdate((prev) => prev + 1); // Force a re-render
    } catch (error) {
      console.error("Error generating query expansions:", error);
      console.log("Error details:", JSON.stringify(error, null, 2));
    }

    setIsWandActive(false);
    console.log("handleWandClick completed");
  };

  // Add this function to handle dropdown changes
  const handlePromptChange = (value: string) => {
    const selectedPrompt = promptOptions.find(
      (option) => option.name === value
    );
    if (selectedPrompt) {
      setLocalNotes(selectedPrompt.description);
      setAdditionalNotes(selectedPrompt.description);
      setForceUpdate((prev) => prev + 1);
    }
  };

  return (
    <div className="rounded-lg border border-gray-300">
      <div className="mb-2">
        <Select onValueChange={handlePromptChange}>
          <SelectTrigger className="w-full">
            <SelectValue placeholder="Select A Report Template" />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              {promptOptions.map((option, index) => (
                <SelectItem key={index} value={option.name}>
                  {option.name}
                </SelectItem>
              ))}
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>
      <div style={{ overflowY: "auto" }} aria-disabled={isWandActive}>
        <MDXEditor
          key={forceUpdate} // Force re-creation of the editor when forceUpdate changes
          onChange={(markdown) => {
            setLocalNotes(markdown);
            setAdditionalNotes(markdown);
          }}
          className="copilotKitMarkdown min-h-1/2 max-h-1/2"
          markdown={localNotes}
          plugins={[
            tablePlugin(),
            headingsPlugin(),
            listsPlugin(),
            quotePlugin(),
            thematicBreakPlugin(),
            markdownShortcutPlugin(),
            toolbarPlugin({
              toolbarContents: () => (
                <>
                  <UndoRedo />
                  <BoldItalicUnderlineToggles />
                  <BlockTypeSelect />
                  <InsertTable />
                  <ListsToggle />
                  {showWandButton && (
                    <button
                      onClick={handleWandClick}
                      className={cn(
                        "inline-flex items-center justify-center text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
                        "h-10 w-10 p-0 text-black hover:bg-accent/60"
                      )}
                    >
                      <WandIcon className="h-6 w-6" />
                    </button>
                  )}
                  {isWandActive && <span>Loading...</span>}
                </>
              ),
            }),
          ]}
          placeholder="Please type in your own prompt or choose one we have pre-written for particular situations which you may edit."
        />
      </div>
    </div>
  );
};

interface ReportDocumentsProps {
  uploadedDocs: SingleDocResponseData[];
  handleUploadComplete: (docResponse: SingleDocResponseData) => void;
}

const ReportDocuments: React.FC<ReportDocumentsProps> = ({
  handleUploadComplete,
}) => {
  return (
    <UploadArea
      db_document_type="attachment"
      onUploadComplete={handleUploadComplete}
    />
  );
};
