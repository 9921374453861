import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useChatContext } from "~/components/chat/ChatContext";
import useSpeechToText from "react-hook-speech-to-text";

import Mic from "~/components/Mic";
import { WandIcon } from "lucide-react";
import { cn } from "~/utils";

import { generateQueryExpansionsChatLLMService } from "~/api/query_fns/query-expansion";

interface InputProps {
  inProgress: boolean;
  onSend: (text: string) => void;
  children?: React.ReactNode;
  showWand?: boolean; // Add this line
}

export const CustomChatInput = ({
  inProgress,
  onSend,
  showWand = false,
}: InputProps) => {
  const context = useChatContext();
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [listening, setListening] = useState(false); // Add this line
  const [previousText, setPreviousText] = useState("");

  const [isWandActive, setIsWandActive] = useState(false);
  const [isDisabledInput, setDisabledInput] = useState(false);

  const { stopSpeechToText } = useSpeechToText({
    continuous: true,
    useLegacyResults: false,
  });

  const handleDivClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target !== event.currentTarget) return;
    textareaRef.current?.focus();
  };

  const [inputPlaceholder, setInputPlaceholder] =
    useState("Ask a question ...");
  const [text, setText] = useState("");

  const onMicButtonPress = (placeholder: string) => {
    setInputPlaceholder(placeholder);
  };

  const send = () => {
    if (inProgress) return;
    onSend(text);
    setText("");
    setListening(false);
    textareaRef.current?.focus();
    stopSpeechToText();
  };

  const onSpeechResult = (text: string) => {
    console.log("Received speech result:", text);
    if (text === previousText) return;

    console.log("Speech result:", text);
    setPreviousText(text);
    setText(text.charAt(0).toUpperCase() + text.slice(1));
    if (text.trim().toLowerCase() === "go") {
      setText("");
    }
    const lastWord = text.trim().split(" ").pop();
    if (lastWord?.toLowerCase() === "go") {
      console.log("Last word is 'go', sending message...");
      send();
      // Stop listening after sending the message
      //setListening(false);
      // Clear the text to prevent recursive sending
    }
  };

  const handleWandClick = async () => {
    // Toggle the wand state
    setIsWandActive((prevState) => !prevState);

    // Add your wand functionality here
    // console.log("Wand clicked, active:", !isWandActive);
    console.log("Wand clicked text:", text);
    const currentText = text;
    setInputPlaceholder("Loading...");
    setDisabledInput(false);
    setText("");

    try {
      const response = await generateQueryExpansionsChatLLMService({
        user_request: currentText,
        documents: [],
      });
      if (response) {
        setText(
          JSON.stringify(response.report_interpreter_analysis.improved_request)
        );
      }
    } catch (error) {
      setText(currentText);
      console.error("Error generating query expansions:", error);
    }

    setInputPlaceholder("Ask a question ...");
    setDisabledInput(false);
    setIsWandActive((prevState) => !prevState);
  };

  const icon = inProgress ? context.icons.activityIcon : context.icons.sendIcon;
  const disabled = inProgress || text.length === 0;

  return (
    <div>
      <div className="copilotKitInput flex flex-col" onClick={handleDivClick}>
        <div className="flex w-full items-center">
          <div className="flex items-center justify-start">
            <Mic
              onMicButtonPress={onMicButtonPress}
              onSpeechResult={onSpeechResult}
              listening={listening}
              setListening={setListening}
            />
            {showWand && (
              <button
                onClick={handleWandClick}
                disabled={isWandActive}
                className={cn(
                  "inline-flex items-center justify-center rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
                  "h-10 w-10 p-0",
                  isWandActive
                    ? "border border-gray-300 text-black hover:bg-accent/60"
                    : "text-gray-500 hover:bg-accent hover:text-accent-foreground"
                )}
              >
                <WandIcon className="h-6 w-6" />
              </button>
            )}
          </div>
          <div className="relative mr-2 flex h-10 w-10 cursor-pointer items-center justify-center text-gray-500 transition-transform duration-300 ease-in-out hover:scale-105"></div>

          <div className="flex flex-grow items-center">
            <AutoResizingTextarea
              ref={textareaRef}
              placeholder={inputPlaceholder}
              autoFocus={true}
              maxRows={5}
              value={text}
              isDisabled={isDisabledInput}
              onChange={(event) => setText(event.target.value)}
              onKeyDown={(event) => {
                if (event.key === "Enter" && !event.shiftKey) {
                  event.preventDefault();
                  send();
                }
              }}
              className="w-full"
            />
          </div>

          {/* <span>{children}</span> */}

          <button
            className="copilotKitSendButton"
            disabled={disabled}
            onClick={send}
          >
            {icon}
          </button>
        </div>
      </div>
      <div className="w-full text-center text-xs italic text-gray-500">
        *Qumis clerk is in Beta. Consider verifying information.
      </div>
    </div>
  );
};

interface AutoResizingTextareaProps {
  maxRows?: number;
  placeholder?: string;
  value: string;
  isDisabled: boolean;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  autoFocus?: boolean;
  className?: string; // Add this line
}

const AutoResizingTextarea = forwardRef<
  HTMLTextAreaElement,
  AutoResizingTextareaProps
>(
  (
    {
      maxRows = 1,
      placeholder,
      value,
      isDisabled,
      onChange,
      onKeyDown,
      autoFocus,
      className,
    },
    ref
  ) => {
    const internalTextareaRef = useRef<HTMLTextAreaElement>(null);
    const [maxHeight, setMaxHeight] = useState<number>(0);

    useImperativeHandle(
      ref,
      () => internalTextareaRef.current as HTMLTextAreaElement
    );

    useEffect(() => {
      const calculateMaxHeight = () => {
        const textarea = internalTextareaRef.current;
        if (textarea) {
          textarea.style.height = "auto";
          const singleRowHeight = textarea.scrollHeight;
          setMaxHeight(singleRowHeight * maxRows);
          if (autoFocus) {
            textarea.focus();
          }
        }
      };

      calculateMaxHeight();
    }, [maxRows]);

    useEffect(() => {
      const textarea = internalTextareaRef.current;
      if (textarea) {
        textarea.style.height = "auto";
        textarea.style.height = `${Math.min(
          textarea.scrollHeight,
          maxHeight
        )}px`;
      }
    }, [value, maxHeight]);

    return (
      <textarea
        ref={internalTextareaRef}
        value={value}
        disabled={isDisabled || false}
        onChange={onChange}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        className={`focus:outline-none focus:ring-0 ${className || ""}`} // Update this line
        style={{
          overflow: "hidden",
          resize: "none",
          maxHeight: `${maxHeight}px`,
        }}
        rows={1}
      />
    );
  }
);

export default CustomChatInput;
