import React, { useEffect } from "react";
import { DocumentType } from "~/api/query_fns/documents";
import { SingleDocResponseData } from "~/api/query_fns/documents";
import uploadingFile from "~/../public/preloader_animation.svg";

interface DocumentDetails {
  fileName: string;
  presignedUrl: string;
}

export const getDocumentDetails = (
  documents: SingleDocResponseData[],
  documentType: string
): DocumentDetails => {
  const document = documents.find(
    (doc) => doc.document.documentType === documentType
  );
  return {
    fileName: document ? document.document.filename : "",
    presignedUrl: document ? document.presignedUrl : "",
  };
};

export function generateInitialMessage(
  reportType: string,
  from?: string,
  followOnQuestions?: string[]
): string {
  let initialMessage = "";

  switch (reportType) {
    case "policies_compare":
      initialMessage = `Hi${
        from ? ` ${from}` : ""
      } 👋  - We have generated a report comparing the insurance policies. Please review the report and let us know if you have any other questions.
          \n For example, you might want to ask:
          \n - Which form is better for a private chemicals company with an HQ in NY and 5000 employees in 20 states?
          \n - Draft your recommendation into a concise email to the prospective customer
          `;
      break;
    case "policy_fact":
      initialMessage = `Hi${
        from ? ` ${from}` : ""
      } 👋   - We have generated a report comparing the insurance policy and fact pattern based on the documents you uploaded. Please review the report and let us know if you have any other questions.
          \n For example, you might want to ask:
          \n - What are the key conditions the insured must comply with for coverage to apply under this policy?
          \n - Prepare a reservation of rights letter based on your report to send to the insured`;
      break;
    case "policy_question":
      initialMessage = `Hi${
        from ? ` ${from}` : ""
      }, 👋 - If you have any further questions about this policy, please let us know below.
          \n For example, you might want to ask:
          \n - The insured is a general contractor, would this policy cover a claim by a subcontractor for an injury at the worksite ?
          \n - What are the key conditions the insured must comply with for coverage to apply under this policy?
          \n - How does a endorsement apply to a specific company or industry ? E.g. provided a detailed explaination of how the total pollution exclusion applies specifically for food manufacturers 
    
          `;
      break;
    case "generic_report":
      initialMessage = `Hi${
        from ? ` ${from}` : ""
      } 👋 - We have generated a report based on the documents you uploaded. Please review the report and let us know if you have any other questions.`;
      break;
    default:
      initialMessage = `Hi${
        from ? ` ${from}` : ""
      } 👋 - We have generated a report based on the documents you uploaded. Please review the report and let us know if you have any other questions.`;
  }

  if (followOnQuestions && followOnQuestions.length > 0) {
    for (let i = 0; i < 2; i++) {
      if (followOnQuestions[i]) {
        initialMessage += `\n - ${followOnQuestions[i]}`;
      }
    }
  }

  return initialMessage;
}

export function getContentTypes(documents: DocumentType[]) {
  const policyDocuments = documents.filter(
    (doc) => doc.documentType === "policy"
  );
  const factDocuments = documents.filter((doc) => doc.documentType === "fact");
  const referenceDocuments = documents.filter(
    (doc) => doc.documentType === "reference"
  );
  const additionalDocuments = documents.filter(
    (doc) => doc.documentType === "attachment"
  );
  const additionalNotesDocuments = documents.filter(
    (doc) => doc.documentType === "additional_notes"
  );

  let policyContent = "",
    factContent = "",
    referenceContent = "",
    additionalContent = "",
    additionalNotesContent = "";

  const policyCount = policyDocuments.length;
  const policyFileNames = policyDocuments.map((doc) => doc.filename).join(", ");
  const policySummary = `There are a total of ${policyCount} number of insurance policies with the following filenames: ${policyFileNames}\n`;
  policyContent = policySummary;

  policyDocuments.forEach((doc) => {
    policyContent += `File Name: ${doc.filename}\nContent:\n${doc.text}\n\n`;
  });

  factDocuments.forEach((doc) => {
    factContent += `File Name: ${doc.filename}\nContent:\n${doc.text}\n\n`;
  });

  referenceDocuments.forEach((doc) => {
    referenceContent += `File Name: ${doc.filename}\nContent:\n${doc.text}\n\n`;
  });

  additionalDocuments.forEach((doc) => {
    additionalContent += `File Name: ${doc.filename}\nContent:\n${doc.text}\n\n`;
  });

  additionalNotesDocuments.forEach((doc) => {
    additionalNotesContent += `File Name: ${doc.filename}\nContent:\n${doc.text}\n\n`;
  });

  additionalContent = additionalContent + additionalNotesContent;
  return { factContent, policyContent, additionalContent, referenceContent };
}

export const generateChatContext = (
  coverageReport: string,
  factContent: string,
  policyContent: string,
  additionalContent: string,
  referenceContent: string
): string => {
  return `
  Please act as an efficient, competent, conscientious, and industrious professional assistant, who is an expert in insurance.

  Help the user achieve their goals, and you do so in a way that is as efficient as possible, without unnecessary fluff, but also without sacrificing professionalism.
  Always be polite and respectful, and prefer brevity over verbosity.

  You should take great care in referencing any part of the document with page numbers when answering questions. 
  You can ask them for clarifying questions if needed, but don't be annoying about it. If you can reasonably 'fill in the blanks' yourself, do so.

  For any phrases that reference any documents please include the filename and page number in the following format, including the square brackets: [ example.pdf, pg X].
  The reference must always be after the phrase and inline.

  Example: Reporting of actual or alleged contamination or tampering in media, specifically naming the Insured and the product [23-24 MRM Product Recall Policy.pdf, pg 6]

  Also try to be as specific as possible to include other text metadata is available, such as sections, CG number, paragraghs etc in brackets ().
  
  Remember:  
  - Always provide the response in markdown
  - Be comprehensive in your review of the full context before providing an concise answer, Create a plan before answering the question.
  - Comprehensively review every provision in all policies and documents before answering the question
  - The reference must always be after the phrase, inline in the following format [ example.pdf, pg X]
  
  ${
    factContent
      ? `
  Fact Pattern""""
  Content: ${factContent}
  """"
  `
      : ""
  }

  Insurance Policies""":
  Content: ${policyContent}
  """"""

  ${
    additionalContent
      ? `
  Additional Documents:
  Content: ${additionalContent}
  `
      : ""
  }

  ${
    referenceContent
      ? `
  Reference Documents:
  Content: ${referenceContent}
  `
      : ""
  }


  Coverage Report:
  File Name: Coverage Report
  Content: ${coverageReport}


  For any phrases that reference any documents please include the filename and page number in the following format, including the square brackets: [ example.pdf, pg X].
  The reference must always be after the phrase and inline.
  `;
};

export const LoadingComponent = ({
  title = "GENERATING REPORT",
  showProgressBar = true,
  message = "*This process may take a few minutes. Thank you for your patience.",
}) => {
  const titleFormat = title
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
  const [progress, setProgress] = React.useState(0);
  const totalTime = 360; // 6 minutes in seconds
  const maxProgress = 95; // Maximum progress percentage

  useEffect(() => {
    if (!showProgressBar) return;

    let timer: NodeJS.Timeout;
    const updateProgress = () => {
      const secondsPassed = (progress / maxProgress) * totalTime;
      if (secondsPassed < totalTime) {
        const newProgress = ((secondsPassed + 1) / totalTime) * maxProgress;
        setProgress(Math.min(newProgress, maxProgress));
        timer = setTimeout(updateProgress, 1000);
      }
    };
    timer = setTimeout(updateProgress, 1000);
    return () => clearTimeout(timer);
  }, [progress, totalTime, showProgressBar]);

  return (
    <div className="mb-10 flex flex-row ">
      <div className="flex w-1/2 flex-col items-center justify-center space-y-3">
        <img
          src={uploadingFile}
          alt="Uploading File"
          style={{
            alignSelf: "center",
            animation: "scaleAnimation 2s ease-in-out infinite",
            boxSizing: "border-box",
            display: "block",
            height: "456px",
            maxWidth: "100%",
            width: "456px",
          }}
        />
        <style>{`
          @keyframes scaleAnimation {
            0%, 100% { transform: scale(1); }
            50% { transform: scale(1.05); }
          }
        `}</style>
      </div>
      <div className="h-full w-px bg-gray-300"></div>
      <div className="flex w-1/2 flex-col justify-center space-y-3">
        <h1 className="mb-0.5 text-2xl font-bold">
          {titleFormat} <span className="blinking">...</span>
        </h1>
        <p className="mb-4 cursor-pointer text-xs text-gray-500">{message}</p>
        {showProgressBar && (
          <div className="w-full">
            <div className="h-4 w-full overflow-hidden rounded-full bg-gray-200">
              <div
                className="h-full bg-black transition-all duration-1000 ease-linear"
                style={{
                  width: `${progress}%`,
                  transition: "width 1s linear",
                }}
              ></div>
            </div>
            <div className="mt-2 text-right text-black">
              {Math.round(progress)}%
            </div>
          </div>
        )}
        <style>{`
          .blinking {
            animation: blinkingText 1.5s infinite;
          }
          @keyframes blinkingText {
            0% { opacity: 1; }
            50% { opacity: 0; }
            100% { opacity: 1; }
          }
        `}</style>
      </div>
    </div>
  );
};
