import { apiFn } from "~/api/utils";

export type DbDocumentType =
  | "policy"
  | "reference"
  | "fact"
  | "attachment"
  | "additional_notes";

export interface CreateRequestParams {
  filename: string;
  document_type?: DbDocumentType;
  claim_number?: string;
  claim_color?: string;
}

interface GetRequestParams {
  id: string;
  includes?: string;
}

interface IndexRequestParams {
  document_type?: DbDocumentType;
  page?: number;
  uploader?: string;
}

export interface DocumentType {
  id: string;
  filename: string;
  documentType: DbDocumentType;
  organizationName: string;
  uploaderEmail: string;
  createdAt: string;
  claimNumber: string;
  claimColor: string;
  markdown?: string;
  chunks?: {
    text: string;
    page: number;
  }[];
  text?: string;
}

export interface SingleDocResponseData {
  document: DocumentType;
  presignedUrl: string;
}

export interface MultipleDocsResponseData {
  documents: DocumentType[];
  total: number;
}

export const getDocument = apiFn<GetRequestParams, SingleDocResponseData>(
  "GET",
  (params) => `/documents/${params.id}`
);

export const getDocuments = apiFn<IndexRequestParams, MultipleDocsResponseData>(
  "GET",
  "/documents"
);

export const createDocument = apiFn<CreateRequestParams, SingleDocResponseData>(
  "POST",
  "/documents"
);

export const destroyDocument = apiFn<{ id: string }, void>(
  "DELETE",
  (params) => `/documents/${params.id}`
);
