import React, { FC, memo } from "react";
import ReactMarkdown, { Components, Options } from "react-markdown";
import remarkGfm from "remark-gfm";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "~/components/ui/tooltip";
import { OpenInNewWindowIcon } from "@radix-ui/react-icons";
import { openReferenceType } from "doc-util";

const MemoizedReactMarkdown: FC<Options> = memo(
  (props) => <ReactMarkdown {...props} className={"milkdown-theme-nord"} />,
  (prevProps, nextProps) =>
    prevProps.children === nextProps.children &&
    prevProps.className === nextProps.className
);

type MarkdownProps = {
  content: string;
  openReference: openReferenceType;
};

const CustomParagraph: FC<{
  children?: React.ReactNode;
  openReference: (text: string) => void;
}> = ({ children, openReference }) => {
  const regex = /\[(.*?)\]/g;

  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement>,
    text: string
  ) => {
    event.preventDefault();
    openReference(text);
    console.log(`Link clicked: ${text}`);
  };

  const processText = (text: string) => {
    const parts = text.split(regex);
    return parts.map((part, index) => {
      if (index % 2 === 1) {
        return (
          <span
            key={index}
            style={{ display: "inline-flex", alignItems: "center" }}
          >
            <Tooltip>
              <TooltipTrigger asChild>
                <a
                  href="#"
                  onClick={(event) => handleClick(event, part)}
                  key={index}
                >
                  <OpenInNewWindowIcon className="h-4 w-4" />
                </a>
              </TooltipTrigger>
              <TooltipContent>
                <span>{part}</span>
              </TooltipContent>
            </Tooltip>
          </span>
        );
      }
      return part;
    });
  };

  return (
    <p>
      {React.Children.map(children, (child) =>
        typeof child === "string"
          ? child.split("\n").map((line, index, array) => (
              <React.Fragment key={index}>
                {processText(line)}
                {index < array.length - 1 && line.trim().length > 0 && <br />}
              </React.Fragment>
            ))
          : child
      )}
    </p>
  );
};

const CustomTable: FC<{
  children?: React.ReactNode;
  openReference: (text: string) => void;
}> = ({ children, openReference, ...props }) => {
  const regex = /\[(.*?)\]/g;

  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement>,
    text: string
  ) => {
    event.preventDefault();
    openReference(text);
    console.log(`Link clicked: ${text}`);
  };

  const processText = (text: string) => {
    const parts = text.split(regex);
    return parts.map((part, index) => {
      if (index % 2 === 1) {
        return (
          <span
            key={index}
            style={{ display: "inline-flex", alignItems: "center" }}
          >
            <Tooltip>
              <TooltipTrigger asChild>
                <a
                  href="#"
                  onClick={(event) => handleClick(event, part)}
                  key={index}
                >
                  <OpenInNewWindowIcon className="h-4 w-4" />
                </a>
              </TooltipTrigger>
              <TooltipContent>
                <span>{part}</span>
              </TooltipContent>
            </Tooltip>
          </span>
        );
      }
      return part;
    });
  };

  const processChildren = (children: React.ReactNode): React.ReactNode => {
    return React.Children.map(children, (child) => {
      if (typeof child === "string") {
        return processText(child);
      } else if (React.isValidElement(child)) {
        return React.cloneElement(child as React.ReactElement, {
          children: processChildren(child.props.children),
        });
      }
      return child;
    });
  };

  return (
    <div className="custom-table">
      <table {...props}>{processChildren(children)}</table>
    </div>
  );
};

const CustomListItem: FC<{
  children?: React.ReactNode;
  openReference: (text: string) => void;
}> = ({ children, openReference, ...props }) => {
  const processChildren = (child: React.ReactNode): React.ReactNode => {
    if (typeof child === "string") {
      return processText(child);
    }
    if (React.isValidElement(child)) {
      return React.cloneElement(
        child as React.ReactElement<{ children?: React.ReactNode }>,
        {
          children: React.Children.map(child.props.children, processChildren),
        }
      );
    }
    return child;
  };

  const processText = (text: string) => {
    const regex = /\[(.*?)\]/g;
    const parts = text.split(regex);
    return parts.map((part, index) => {
      if (index % 2 === 1) {
        return (
          <span
            key={index}
            style={{ display: "inline-flex", alignItems: "center" }}
          >
            <Tooltip>
              <TooltipTrigger asChild>
                <a
                  href="#"
                  onClick={(event) => {
                    event.preventDefault();
                    openReference(part);
                  }}
                >
                  <OpenInNewWindowIcon className="h-4 w-4" />
                </a>
              </TooltipTrigger>
              <TooltipContent>
                <span>{part}</span>
              </TooltipContent>
            </Tooltip>
          </span>
        );
      }
      return part;
    });
  };

  return <li {...props}>{React.Children.map(children, processChildren)}</li>;
};

const CustomPreBlock: FC<{
  children?: React.ReactNode;
  openReference: (text: string) => void;
}> = ({ children, openReference }) => {
  const processText = (text: string) => {
    const regex = /\[(.*?)\]/g;
    const parts = text.split(regex);
    return parts.map((part, index) => {
      if (index % 2 === 1) {
        return (
          <span
            key={index}
            style={{ display: "inline-flex", alignItems: "center" }}
          >
            <Tooltip>
              <TooltipTrigger asChild>
                <a
                  href="#"
                  onClick={(event) => {
                    event.preventDefault();
                    openReference(part);
                  }}
                >
                  <OpenInNewWindowIcon className="h-4 w-4" />
                </a>
              </TooltipTrigger>
              <TooltipContent>
                <span>{part}</span>
              </TooltipContent>
            </Tooltip>
          </span>
        );
      }
      return part;
    });
  };

  return (
    <div className="custom-pre">
      {React.Children.map(children, (child) => {
        if (typeof child === "string") {
          return processText(child);
        }
        return child;
      })}
    </div>
  );
};

export const CustomMarkdown: FC<
  MarkdownProps & { maxHeight?: string; className: string }
> = ({ content, openReference, maxHeight, className }) => {
  const components: Components = {
    p: ({ children }) => (
      <CustomParagraph openReference={openReference}>
        {children}
      </CustomParagraph>
    ),
    pre: ({ children, ...props }) => (
      <CustomPreBlock {...props} openReference={openReference}>
        {children}
      </CustomPreBlock>
    ),
    code: ({ children, ...props }) => <code {...props}>{children}</code>,
    li: ({ ...props }) => (
      <CustomListItem
        className="custom-paragraph"
        {...props}
        openReference={openReference}
      />
    ),
    img: ({ ...props }) => (
      <img
        style={{ maxWidth: "100%", margin: "0", display: "inline-block" }}
        {...props}
      />
    ),
    blockquote: ({ ...props }) => (
      <blockquote style={{ fontWeight: "normal" }} {...props} />
    ),
    ol: ({ ...props }) => (
      <ol style={{ listStyleType: "decimal" }} {...props} />
    ),
    ul: ({ ...props }) => <ul style={{ listStyleType: "disc" }} {...props} />,
    table: ({ ...props }) => (
      <CustomTable
        className="custom-paragraph"
        {...props}
        openReference={openReference}
      />
    ),
    td: ({ ...props }) => (
      <td
        style={{
          border: "1px solid #E5E7EB",
          padding: "0.75rem 1.5rem",
          textAlign: "center",
        }}
        {...props}
      />
    ),
    th: ({ ...props }) => (
      <th style={{ padding: "0.75rem 1.5rem" }} {...props} />
    ),
    tr: ({ ...props }) => (
      <tr style={{ border: "1px solid #E5E7EB" }} {...props} />
    ),
  };

  return (
    <TooltipProvider>
      <div
        className={`${className} prose prose-sm max-w-none`}
        style={{ maxHeight: maxHeight || undefined }}
      >
        <MemoizedReactMarkdown
          components={components}
          remarkPlugins={[remarkGfm]}
          className="space-y-4"
        >
          {content}
        </MemoizedReactMarkdown>
      </div>
    </TooltipProvider>
  );
};
