/**
 * v0 by Vercel.
 * @see https://v0.dev/t/bz8QpogGrc1
 * Documentation: https://v0.dev/docs#integrating-generated-code-into-your-nextjs-app
 */
import { SingleDocResponseData } from "~/api/query_fns/documents";
import { StepReportComponent } from "../components/StepReportComponent";
import AdditionalInformation from "~/components/AdditionalInformation";
import UploadArea from "./Documents/UploadArea";
import { useGenerateReportNew } from "~/hooks/useGenerateReportNew";
import { LoadingComponent } from "~/utils/util-report";

export default function UCPolicyComparisonNew() {
  const {
    isLoading,
    handleGenerateReport,
    additionalNotes,
    setAdditionalNotes,
    handleUploadComplete,
  } = useGenerateReportNew("policies_compare");

  if (isLoading) {
    return <LoadingComponent title="GENERATING POLICY COMPARISON" />;
  }

  return (
    <StepReportComponent
      title="Generate Policy Comparison"
      lastStepName="Generate Policy Comparison"
      steps={[
        {
          number: 1,
          label: "Policy Documents",
          description:
            "Upload the policies you would like to compare here. Please drag and drop them from your computer. This tool will analyze and compare the contents of the policies you upload. For best results please only upload complete policies and not a single policy in multiple different PDFs or other types of documents.",
          component: (
            <PolicyDocumentsComponent
              handleUploadComplete={handleUploadComplete}
            />
          ),
        },
        {
          number: 2,
          label: "Additional Information",
          description:
            "You can provide additional information or context here for the report. Please note that this text box will not change the format of this report. If you would like to generate a custom report with your own instructions or formatting, please use the Custom Report tool from the home screen.",
          component: (
            <AdditionalInformationComponent
              additionalNotes={additionalNotes}
              setAdditionalNotes={setAdditionalNotes}
              handleUploadComplete={handleUploadComplete}
            />
          ),
        },
      ]}
      onFinalStep={handleGenerateReport}
      onCancelPath="/policy-comparison"
    />
  );
}

const PolicyDocumentsComponent = ({
  handleUploadComplete,
}: {
  handleUploadComplete: (docResponse: SingleDocResponseData) => void;
}) => {
  return (
    <div id="step1" className="flex w-full flex-col">
      <UploadArea
        db_document_type="policy"
        onUploadComplete={handleUploadComplete}
      />
    </div>
  );
};

const AdditionalInformationComponent = ({
  additionalNotes,
  setAdditionalNotes,
  handleUploadComplete,
}: {
  additionalNotes: string;
  setAdditionalNotes: (notes: string) => void;
  handleUploadComplete: (docResponse: SingleDocResponseData) => void;
}) => {
  return (
    <AdditionalInformation
      additionalNotes={additionalNotes}
      setAdditionalNotes={setAdditionalNotes}
      handleUploadComplete={handleUploadComplete}
    />
  );
};
