import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "~/components/ui/dialog-background";
import MainDocument from "~/routes/GenPrecedentSearch/MainDocument";

import { SingleDocResponseData } from "~/api/query_fns/documents";
import { DocViewerState } from "doc-util";

interface PDFViewerDialogProps {
  doc: SingleDocResponseData | null;
  initialPage: number;
  setDocViewerState: (state: DocViewerState) => void;
  open: boolean;
}

export const PDFViewerDialog: React.FC<PDFViewerDialogProps> = ({
  open,
  doc,
  initialPage,
  setDocViewerState,
}) => {
  return (
    <Dialog open={open} defaultOpen={true}>
      <DialogContent
        onClose={() => {
          setDocViewerState({ isOpen: false, doc: doc, page: initialPage });
        }}
        className="fixed left-[32%] top-[39%] h-[97vh] translate-y-[-32%] sm:max-w-[1000px]"
      >
        <DialogHeader>
          <DialogTitle></DialogTitle>
          <DialogDescription></DialogDescription>
        </DialogHeader>

        <div className="max-h-[800px] overflow-auto">
          {doc && (
            <MainDocument
              presignedUrl={doc.presignedUrl || ""}
              persistHighlight={() => null}
              hideDocument={false}
              highlightedContent={""}
              initialPage={initialPage || 0}
            />
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};
