import { useState } from "react";
import { SingleDocResponseData } from "~/api/query_fns/documents";
import { useGenerateReport } from "~/hooks/useGenerateReport";
import ExistingReports from "~/components/ExistingReports";
import { ReportType } from "~/api/query_fns/coverage-analysis";
import UCTemplate from "~/components/UCTemplate";
import UCReportGenerationButtonComponent from "~/components/UCReportGenerationButton";

interface UCReportV2Props {
  reportType: ReportType;
  reportTitle: string;
  newPath: string;
}

const UCGenericReportV2 = ({
  reportType,
  reportTitle,
  newPath,
}: UCReportV2Props) => {
  const [additionalNotes] = useState("");
  const [uploadedDocs] = useState<SingleDocResponseData[]>([]);

  let buttonName: string;
  switch (reportType) {
    case "policy_fact":
      buttonName = "Generate Policy Fact Report";
      break;
    case "policies_compare":
      buttonName = "Generate Policies Comparison Report";
      break;
    case "policy_question":
      buttonName = "Generate Policy Question Report";
      break;
    case "generic_report":
      buttonName = "Generate Custom Report";
      break;
    default:
      buttonName = "Generate Report";
  }

  const { isLoading } = useGenerateReport(
    uploadedDocs,
    reportType,
    additionalNotes
  );

  const LeftComponent = () => (
    <ExistingReports report_title={reportTitle} report_type={reportType} />
  );
  const RightComponent = () => (
    <UCReportGenerationButtonComponent
      route={newPath}
      buttonText={buttonName}
    />
  );

  return (
    <UCTemplate
      LeftComponent={LeftComponent}
      RightComponent={RightComponent}
      isLoading={isLoading}
      loadingTitle="GENERATING REPORT"
    />
  );
};

export default UCGenericReportV2;
